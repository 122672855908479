@font-face {
  font-family: 'SF-Pro-Display-Regular';
  src: url('../assets/fonts/SF-Pro-Display/SFProDisplay-Regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/SF-Pro-Display/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/SF-Pro-Display/SFProDisplay-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/SF-Pro-Display/SFProDisplay-Regular.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'SF-Pro-Display-Medium';
  src: url('../assets/fonts/SF-Pro-Display/SFProDisplay-Medium.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/SF-Pro-Display/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/SF-Pro-Display/SFProDisplay-Medium.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/SF-Pro-Display/SFProDisplay-Medium.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'SF-Pro-Display-Light';
  src: url('../assets/fonts/SF-Pro-Display/SFProDisplay-Light.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/SF-Pro-Display/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/SF-Pro-Display/SFProDisplay-Light.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/SF-Pro-Display/SFProDisplay-Light.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'SF-Pro-Display-Semibold';
  src: url('../assets/fonts/SF-Pro-Display/SFProDisplay-Semibold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/SF-Pro-Display/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/SF-Pro-Display/SFProDisplay-Semibold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/SF-Pro-Display/SFProDisplay-Semibold.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'SF-Pro-Display-Bold';
  src: url('../assets/fonts/SF-Pro-Display/SFProDisplay-Bold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/SF-Pro-Display/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/SF-Pro-Display/SFProDisplay-Bold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/SF-Pro-Display/SFProDisplay-Bold.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'SF-Pro-Text-Regular';
  src: url('../assets/fonts/SF-Pro-Text/SFUIText-Regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/SF-Pro-Text/SFUIText-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/SF-Pro-Text/SFUIText-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/SF-Pro-Text/SFUIText-Regular.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'SF-Pro-Text-Medium';
  src: url('../assets/fonts/SF-Pro-Text/SFUIText-Medium.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/SF-Pro-Text/SFUIText-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/SF-Pro-Text/SFUIText-Medium.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/SF-Pro-Text/SFUIText-Medium.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'SF-Pro-Text-Bold';
  src: url('../assets/fonts/SF-Pro-Text/SFUIText-Bold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/SF-Pro-Text/SFUIText-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/SF-Pro-Text/SFUIText-Bold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/SF-Pro-Text/SFUIText-Bold.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'SF-Pro-Text-Light';
  src: url('../assets/fonts/SF-Pro-Text/SFUIText-Light.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/SF-Pro-Text/SFUIText-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/SF-Pro-Text/SFUIText-Light.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/SF-Pro-Text/SFUIText-Light.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Open-Sans-Regular';
  src: url('../assets/fonts/OpenSans/OpenSans-Regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/OpenSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/OpenSans/OpenSans-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/OpenSans/OpenSans-Regular.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Open-Sans-Bold';
  src: url('../assets/fonts/OpenSans/OpenSans-Bold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/OpenSans/OpenSans-Bold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/OpenSans/OpenSans-Bold.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Open-Sans-SemiBold';
  src: url('../assets/fonts/OpenSans/OpenSans-SemiBold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/OpenSans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/OpenSans/OpenSans-SemiBold.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Open-Sans-Light';
  src: url('../assets/fonts/OpenSans/OpenSans-Light.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/OpenSans/OpenSans-Light.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/OpenSans/OpenSans-Light.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('../assets/fonts/Lato/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Lato/Lato-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/Lato/Lato-Regular.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('../assets/fonts/Lato/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Lato/Lato-Bold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/Lato/Lato-Bold.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Lato-SemiBold';
  src: url('../assets/fonts/Lato/Lato-Semibold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Lato/Lato-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Lato/Lato-Semibold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/Lato/Lato-Semibold.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Lato-Medium';
  src: url('../assets/fonts/Lato/Lato-Medium.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Lato/Lato-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Lato/Lato-Medium.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/Lato/Lato-Medium.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Lato-Light';
  src: url('../assets/fonts/Lato/Lato-Light.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Lato/Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Lato/Lato-Light.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/Lato/Lato-Light.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Barlow-Thin';
  src: url('../assets/fonts/Barlow/Barlow-Thin.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Barlow/Barlow-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Barlow/Barlow-Thin.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/Barlow/Barlow-Thin.ttf')  format('truetype') /* Safari, Android, iOS */
}


@font-face {
  font-family: 'Barlow-Light';
  src: url('../assets/fonts/Barlow/Barlow-Light.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Barlow/Barlow-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Barlow/Barlow-Light.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/Barlow/Barlow-Light.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Barlow-Regular';
  src: url('../assets/fonts/Barlow/Barlow-Regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Barlow/Barlow-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Barlow/Barlow-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/Barlow/Barlow-Regular.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Barlow-Medium';
  src: url('../assets/fonts/Barlow/Barlow-Medium.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Barlow/Barlow-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Barlow/Barlow-Medium.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/Barlow/Barlow-Medium.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Barlow-SemiBold';
  src: url('../assets/fonts/Barlow/Barlow-SemiBold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Barlow/Barlow-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Barlow/Barlow-SemiBold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/Barlow/Barlow-SemiBold.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Barlow-Bold';
  src: url('../assets/fonts/Barlow/Barlow-Bold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Barlow/Barlow-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Barlow/Barlow-Bold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../assets/fonts/Barlow/Barlow-Bold.ttf')  format('truetype') /* Safari, Android, iOS */
}
